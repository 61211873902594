import { getNoopLayout } from '@/components/page-layouts';
import { RedirectTo } from '@/components/redirect-to';
import { withAuthenticatedUser } from '@/components/with-authenticated-user';
import { withOnboardingApprovedStatus } from '@/components/with-onboarding-approved-status';
import { PATH_ACCOUNT_HOME } from '@/constants/routes';
import { compose } from '@/helpers';
import { NextPageWithLayout } from '@/types/next';

export const Index = compose(
  withAuthenticatedUser,
  withOnboardingApprovedStatus,
)(function Index() {
  return <RedirectTo url={PATH_ACCOUNT_HOME} />;
});

(Index as NextPageWithLayout).getPageLayout = getNoopLayout;
